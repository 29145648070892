<template>
    <div class="card card-custom">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
                <h3 class="card-label">Добавление книги</h3>
            </div>
        </div>
        <form>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <BaseInput
                            type="text"
                            :label="'Название *'"
                            :error="errors.get('title')"
                            v-model="form.title"
                        />

                        <BaseInput
                            type="text"
                            :label="' Автор'"
                            :error="errors.get('author')"
                            v-model="form.author"
                        />

                        <BaseInput
                            type="text"
                            :label="'Информация об издательсве'"
                            :error="errors.get('publish_info')"
                            v-model="form.publish_info"
                        />

                        <BaseInput
                            type="text"
                            :label="'Где купить(Ссылка)'"
                            :error="errors.get('link')"
                            v-model="form.link"
                        />
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Изображение *</label>
                            <el-upload
                                class="avatar-uploader"
                                action="''"
                                :auto-upload="false"
                                list-type="picture"
                                accept=".jpeg, .jpg"
                                :show-file-list="false"
                                ref="upload"
                                :on-change="handleOnChangeFile"
                            >
                                <img
                                    v-if="form.original_name && !form.image"
                                    :src="imgSrc"
                                    class="avatar"
                                    style="width: 100%"
                                />

                                <img
                                    v-else-if="form.image"
                                    :src="imgSrc"
                                    class="avatar"
                                />

                                <i
                                    v-else
                                    class="el-icon-plus avatar-uploader-icon"
                                ></i>

                                <div slot="tip" class="el-upload__tip">
                                    Изображение должно быть в формате 1200x367px
                                </div>
                            </el-upload>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('image')"
                            >
                                {{ errors.get("image") }}
                            </div>
                        </div>
                    </div>
                </div>
                <BaseTextarea
                    rows="3"
                    :label="'Краткое описание'"
                    :error="errors.get('short_description')"
                    v-model="form.short_description"
                />
                <wysiwyg v-model="form.text" />

                <div class="form-group mt-5">
                    <!-- <label>Upsale</label> -->
                    <div class="checkbox-list">
                        <label class="checkbox">
                            <input type="checkbox" v-model="form.active" />
                            <span></span>Отображать на сайте</label
                        >
                    </div>
                </div>

                <br />
                <h3>Seo</h3>

                <BaseInput
                    type="text"
                    :label="'SEO title'"
                    :error="errors.get('seo_title')"
                    v-model="form.seo_title"
                />

                <BaseTextarea
                    rows="3"
                    :label="'SEO description'"
                    :error="errors.get('seo_description')"
                    v-model="form.seo_description"
                />
            </div>
            <div class="card-footer">
                <BaseButton @click.prevent="save()">Сохранить</BaseButton>
                <BaseButton
                    btnClass="btn-secondary"
                    @click.prevent="$emit('close')"
                    >Отменить</BaseButton
                >
            </div>
        </form>
    </div>
</template>

<script>
import { Errors } from "@/classes/Errors.js";

export default {
    props: {
        entity: Object,
    },
    computed: {
        imgSrc() {
            return this.form.image && typeof this.form.image == "object"
                ? URL.createObjectURL(this.form.image)
                : this.form.images.medium;
        },
    },
    data() {
        return {
            errors: new Errors(),
            dialogVisible: false,
            form: this.initForm(),
        };
    },

    methods: {
        initForm() {
            return {
                image: null,
                title: null,
                short_description: null,
                text: null,
                type: "article",
                active: false,
                link: null,
                seo_title: null,
                seo_description: null,
                author: null,
                link: null,
                publish_info: null,
            };
        },
        save() {
            if (this.isProcess) {
                return;
            }

            this.isProcess = true;

            this.form["active"] = Number(this.form.active);

            let formData = new FormData();

            for (let key in this.form) {
                if (this.form[key] != null) {
                    formData.set(key, this.form[key]);
                }
            }

            formData.set("action", this.form["action"]);

            if (this.entity) {
                formData.set("_method", "PUT");
            }

            const $url = this.entity ? `/books/${this.entity.id}` : "/books";

            axios
                .post($url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then(() => {
                    this.isProcess = false;

                    this.$notify({
                        type: "success",
                        message: "Книга добавлена!",
                    });
                    this.errors.clearAll();

                    this.$emit("close");
                    this.$emit("onUpdated");
                })
                .catch((error) => {
                    this.isProcess = false;

                    if (error.response && error.response.status == 422) {
                        this.$notify({
                            type: "warning",
                            message: error.response.data.message,
                        });
                        this.errors.set(error.response.data.errors);
                    }

                    if (error.response && error.response.status == 403) {
                        this.$notify({
                            type: "warning",
                            message: error.response.data.message,
                        });
                    }
                });
        },

        handleOnChangeFile(file) {
            this.form.image = file.raw;
        },

        loadEntity(id) {
            axios
                .get(`/books/${id}`)
                .then((res) => {
                    this.form = Object.assign({}, this.initForm(), res.data);
                })
                .catch((error) => {
                    this.$notify({
                        type: "error",
                        message: "Не удалось загрузить данные",
                    });
                });
        },
    },

    created() {
        if (this.entity) {
            this.loadEntity(this.entity.id);
        }
    },
};
</script>

<style>
.avatar {
    width: 100%;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 100%;
}
.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 278px;
    line-height: 178px;
    text-align: center;
}
</style>
